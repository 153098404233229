<template>
  <div class="ca-page">
    <v-row class="ca-page-title">
      <v-col>
        <h1 class="ca-title">The better we know you...</h1>
        <h2 class="ca-subtitle">The better the creative!</h2>
      </v-col>
    </v-row>
      <v-row class="ca-content">
        <v-col class="t-view">
          <v-row class="top justify-space-between text-center">
            <v-col class="pr-6 pl-0">
              <h2>Hi!</h2>
            </v-col>
            <v-col>
              <h2>Good to meet you!</h2>
            </v-col>
            <v-col class="pl-6 pr-0">
              <h2>Great to know you!</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-img
                class="tablet-graphic"
                contain
                src="../assets/images/graphics/Sokal_Creative-Analyisis_p2_icon-04.png"
              >
              </v-img>
            </v-col>
          </v-row>
          <v-row class="bottom justify-space-between text-center">
            <v-col class="pr-6 pl-0">
              <h2>Good</h2>
            </v-col>
            <v-col>
              <h2>Better</h2>
            </v-col>
            <v-col class="pl-6 pr-0">
              <h2>Best</h2>
            </v-col>
          </v-row>
        </v-col>
        <div class="m-view col px-0">
          <div class="mobile-block row">
            <v-col class="align-center d-flex pr-0 flex-grow-0">
              <h3 class="helper-text">Good</h3>
            </v-col>
            <v-col class="align-center d-flex pl-0 flex-grow-0">
              <img
              class="graphic"
              src="../assets/images/graphics/Sokal_Creative-Analyisis_p2_icon-01.png"
            />
            </v-col>
            <v-col class="align-center d-flex">
              <h2>Hi!</h2>
            </v-col>
          </div>
          <div class="mobile-block row">
            <v-col cols="6">
              <div class="vertical"></div>
            </v-col>
          </div>
          <div class="mobile-block row">
            <v-col class="align-center d-flex pr-0 flex-grow-0">
              <h3 class="helper-text">Better</h3>
            </v-col>
            <v-col class="align-center d-flex pl-0 flex-grow-0">
              <img
                class="graphic"
                src="../assets/images/graphics/Sokal_Creative-Analyisis_p2_icon-02.png"
              />
            </v-col>
            <v-col class="align-center d-flex">
              <h2>Good to meet you!</h2>
            </v-col>
          </div>
          <div class="mobile-block row">
            <v-col cols="6">
              <div class="vertical"></div>
            </v-col>
          </div>
          <div class="mobile-block row">
            <v-col class="align-center d-flex pr-0 flex-grow-0">
              <h3 class="helper-text">Best</h3>
            </v-col>
            <v-col class="align-center d-flex pl-0 flex-grow-0">
              <img
                class="graphic"
                src="../assets/images/graphics/Sokal_Creative-Analyisis_p2_icon-03.png"
              />
            </v-col>
            <v-col class="align-center d-flex">
              <h2>Great to know you!</h2>
            </v-col>
          </div>
        </div>
      </v-row>
  </div>
</template>

<script>
export default {
  mounted () {
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped>
.ca-title {
  text-transform: none;
}
.vertical {
  background: #798086;
  height: 50px;
  width: 3px;
  margin-left: 143px;
}
.m-view {
  margin: auto;
}
.m-view .mobile-block {
  width: calc(100% + 24px);
  flex-wrap: nowrap;
}
.m-view .mobile-block h3 {
  margin-bottom: 0px;
  text-transform: uppercase;
  min-width: 66px;
}
.tablet-graphic {
  clear: both;
  margin: auto;
}
.graphic {
  float: left;
  max-width: 120px;
}
.top {
  margin-top: auto;
  margin-bottom: auto;
}
.bottom {
  font-size: 20px;
  font-weight: bold;
  margin-top: 50px;
}

@media (min-width: 599px) {
  .m-view {
    display: none;
  }
}

@media (max-width: 599px) {
  .t-view {
    display: none;
  }
}
</style>
